<template lang="pug">
.nightsky-social-links
  .wrapper
    .avatar-container
      .avatar
      img.tschuki(src="~@/assets/tschuki_text.png")
      tschuki-foo
    .card
      .button-group.first
        a.button.twitch(
          href="https://www.twitch.tv/tschuki_tv",
          target="_blank"
        )
          span.mdi.mdi-twitch
          span Twitch
        a.button.youtube(
          href="https://www.youtube.com/tschukitv",
          target="_blank"
        )
          span.mdi.mdi-youtube
          span Youtube
        a.button.youtube(
          href="https://www.youtube.com/channel/UCX4il5GVeU4yhQxXaUDt0Ug",
          target="_blank"
        )
          span.mdi.mdi-youtube
          span Stream Archiv
        a.button.instagram(
          href="https://www.instagram.com/tschukitv",
          target="_blank"
        )
          span.mdi.mdi-instagram
          span Instagram
      .button-group.second
        a.button.twitter(
          href="https://www.twitter.com/tschukitv",
          target="_blank"
        )
          span.mdi.mdi-twitter
          span Twitter
        a.button.kofi(href="https://ko-fi.com/tschuki", target="_blank")
          img.kofi-img(src="@/assets/kofi_cup.png")
          span Unterstütze mich auf Ko-Fi
        a.button.discord(href="https://discord.gg/h4VpFAYRqg", target="_blank")
          span.mdi.mdi-discord
          span Discord
        a.button.contact(href="mailto:tschukitv@gmail.com", target="_blank")
          span.mdi.mdi-email
          span Kontakt
    .footer
      a.link(href="https://wiki.tschuki.tv/#impressum") Impressum
</template>

<script>
import TschukiFoo from "./tschuki-foo.vue";

export default {
  name: "nightsky-social-links",
  components: { TschukiFoo },
};
</script>

<style lang="stylus" scoped>
.nightsky-social-links
  overflow auto
  position absolute
  left 0
  top 0
  width 100%
  height 100%

  .wrapper
    display flex
    flex-flow column
    justify-content center
    align-items center
    padding-bottom 20px
    box-sizing border-box

  .avatar-container
    display flex
    flex-flow column
    height 100%
    align-items center
    margin-bottom 50px
    margin-top 25px

    .avatar
      width 200px
      height 200px
      background-image url('~@/assets/tschuki.png')
      background-size cover

    .tschuki
      height 100px

  .card
    display flex
    flex-flow column
    justify-content center
    align-items center

    @media only screen and (min-width 900px)
      align-self stretch
      flex-flow row

      div.button-group
        flex 1 1 0%
        display flex
        flex-flow column
        padding 0 20px 20px 20px

        &.first
          align-items end
          margin-bottom 0

        &.second
          align-items start

    .button-group.first
      margin-bottom -4px

    .button
      padding 18px 0px
      margin 4px
      width 300px
      transition all 0.4s ease
      will-change width, box-shadow
      text-align center
      text-transform uppercase
      font-family 'Roboto', sans-serif
      font-size 16px
      color white !important
      text-decoration none !important
      display flex
      justify-content center
      align-items center

      .mdi
        padding-right 12px
        font-size 24px
        margin -6px 0

      &:hover
        width 320px

      &.twitch
        background-color #9246fe
        box-shadow -10px 10px 0 0px #6225c1

        &:hover
          box-shadow 10px 10px 0px 0px #6225c1

      &.youtube
        background-color #ff0000
        box-shadow -10px 10px 0 0px #c10000

        &:hover
          box-shadow 10px 10px 0px 0px #c10000

      &.instagram
        background-color #f46f30
        box-shadow -10px 10px 0 0px #b85628

        &:hover
          box-shadow 10px 10px 0px 0px #b85628

      &.twitter
        background-color #1f9ff5
        box-shadow -10px 10px 0 0px #2070af

        &:hover
          box-shadow 10px 10px 0px 0px #2070af

      &.contact
        background-color #777777
        box-shadow -10px 10px 0 0px #555555

        &:hover
          box-shadow 10px 10px 0px 0px #555555

      &.kofi
        background-color #29abe0
        box-shadow -10px 10px 0 0px #1e86b1

        &:hover
          box-shadow 10px 10px 0px 0px #1e86b1

        .kofi-img
          height 24px
          padding-right 12px

      &.discord
        background-color #7289da
        box-shadow -10px 10px 0 0px #1B307E

        &:hover
          box-shadow 10px 10px 0px 0px #1B307E

.footer
  margin-top 20px
  text-align center

  .link
    color white

    &:active
      color white

@media only screen and (max-width 700px)
  .nightsky-social-links
    .avatar-container
      margin-bottom 35px

      .avatar
        width 150px
        height 150px

      .tschuki
        height 75px

@media only screen and (max-width 485px)
  .nightsky-social-links
    .avatar-container
      margin-bottom 20px

      .avatar
        width 100px
        height 100px

      .tschuki
        height 50px
</style>
