<template lang="pug">
#app
  router-view
</template>

<script>
import "@mdi/font/css/materialdesignicons.min.css";

export default {
  name: "app",
};
</script>

<style lang="stylus">
#app2
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 60px

html, body
  margin 0
  padding 0
</style>
