<template lang="pug">
home-main
</template>

<script>
import HomeMain from "@/components/home/home-main";

export default {
  name: "home",
  components: { HomeMain },
};
</script>
