<template lang="pug">
.home-main
  component(:is="theme.background")
  component(:is="theme.links")
</template>

<script>
import { themes } from "./themes";

export default {
  name: "home-main",
  data() {
    return {
      theme: themes[Math.floor(Math.random() * themes.length)],
    };
  },
};
</script>

<style lang="stylus" scoped>
.home-main
  background-color green
</style>