<template lang="pug">
.nightsky-background
  canvas.stars-canvas(ref="canvas")
  .shooting-wrapper
    .shooting-container(ref="starContainer")
</template>

<script>
// Number of stars
const NUM_STARS = 150;

// Star Settings
const STAR_MIN_SPEED = 0.05;
const STAR_MAX_SPEED = 0.1;
const STAR_MIN_RADIUS = 1;
const STAR_MAX_RADIUS = 2.5;
const STAR_OPACITY_FADE = 0.005;
const STAR_OPACITY_KEEP_TIMER = 5000;

// Star Line Settings
const MAX_LINE_LENGTH = 100;
const LINE_FADE_LENGTH = 30;
const LINE_WIDTH = 0.5;

// Falling Star Timer
const MIN_FALLING_STAR_TIMER = 4000;
const MAX_FALLING_STAR_TIMER = 2000;

// Background Lights
const NUM_BG_LIGHT = 8;

// Background Light Settings
const BG_LIGHT_MIN_SPEED = 0.5;
const BG_LIGHT_MAX_SPEED = 0.75;
const BG_LIGHT_MIN_RADIUS = 200;
const BG_LIGHT_MAX_RADIUS = 500;

function rand(min, max) {
  return min + (max - min) * Math.random();
}

class Star {
  constructor(canvas, context) {
    this.canvas = canvas;
    this.context = context;
    this.x = rand(0.1, canvas.width);
    this.y = rand(0.1, canvas.height);

    this.vx = rand(STAR_MIN_SPEED, STAR_MAX_SPEED);
    if (Math.random() < 0.5) this.vx = -this.vx;
    this.vy = rand(STAR_MIN_SPEED, STAR_MAX_SPEED);
    if (Math.random() < 0.5) this.vy = -this.vy;

    this.radius = rand(STAR_MIN_RADIUS, STAR_MAX_RADIUS);
    this.color = "0, 174, 234";
    this.opacity = Math.random();
    this.opacityMod =
      Math.random() >= 0.5 ? -STAR_OPACITY_FADE : STAR_OPACITY_FADE;
    this.opacityKeepStart = Date.now();
  }

  draw() {
    this.context.fillStyle = `rgba(${this.color}, ${this.opacity}`;
    this.context.beginPath();
    this.context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    this.context.fill();
  }

  move() {
    this.x += this.vx;
    this.y += this.vy;

    if (this.x < 0 || this.x >= this.canvas.height) this.vx = -this.vx;
    if (this.y < 0 || this.y >= this.canvas.width) this.vy = -this.vy;

    const opacityTimer = Date.now() - this.opacityKeepStart;
    if (opacityTimer > STAR_OPACITY_KEEP_TIMER) {
      this.opacity += this.opacityMod;

      if (this.opacity < 0 || this.opacity > 1) {
        this.opacityMod = -this.opacityMod;
        this.opacityKeepStart = Date.now();
      }
    }
  }

  tick() {
    this.move();
    this.draw();
  }
}

class BackgroundLight {
  constructor(canvas, context, gradient) {
    this.canvas = canvas;
    this.context = context;
    this.gradient = gradient;
    this.x = rand(0.1, canvas.width);
    this.y = rand(0.1, canvas.height);

    this.vx = rand(BG_LIGHT_MIN_SPEED, BG_LIGHT_MAX_SPEED);
    if (Math.random() < 0.5) this.vx = -this.vx;
    this.vy = rand(BG_LIGHT_MIN_SPEED, BG_LIGHT_MAX_SPEED);
    if (Math.random() < 0.5) this.vy = -this.vy;

    this.radius = rand(BG_LIGHT_MIN_RADIUS, BG_LIGHT_MAX_RADIUS);
  }

  draw() {
    var radgrad = this.context.createRadialGradient(
      this.x,
      this.y,
      0,
      this.x,
      this.y,
      this.radius
    );
    radgrad.addColorStop(0.0, "rgba(42,158,158,0.2)");
    radgrad.addColorStop(0.5, "rgba(42,158,158,0.15)");
    radgrad.addColorStop(1.0, "rgba(42,158,158,0.0)");

    this.context.fillStyle = radgrad;
    this.context.beginPath();
    this.context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    this.context.fill();
  }

  move() {
    this.x += this.vx;
    this.y += this.vy;

    if (this.x < 0 || this.x >= this.canvas.height) this.vx = -this.vx;
    if (this.y < 0 || this.y >= this.canvas.width) this.vy = -this.vy;
  }

  tick() {
    this.move();
    this.draw();
  }
}

class ConstellationSim {
  constructor(canvas) {
    this.canvas = canvas;
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.context = canvas.getContext("2d");
    this.stars = [];
    this.bgLights = [];
    this.run = false;
    this.frameReq = null;
    for (let i = 0; i < NUM_STARS; i++)
      this.stars.push(new Star(this.canvas, this.context));
    for (let i = 0; i < NUM_BG_LIGHT; i++)
      this.bgLights.push(new BackgroundLight(this.canvas, this.context));

    this.tick = this.tick.bind(this);
  }

  drawBackground() {
    this.context.beginPath();
    this.context.rect(0, 0, this.canvas.width, this.canvas.height);
    this.context.fillStyle = "#182a44";
    this.context.fill();
  }

  tick() {
    if (this.run) this.frameReq = requestAnimationFrame(this.tick);

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawBackground();

    for (let i = 0; i < this.bgLights.length; i++) this.bgLights[i].tick();
    for (let i = 0; i < this.stars.length; i++) this.stars[i].tick();

    this.drawConnections();
  }

  drawLine(starA, starB, opacityMult) {
    const opacity = Math.min(starA.opacity, starB.opacity);
    this.context.strokeStyle = `rgba(0, 174, 234, ${opacity * opacityMult})`;
    this.context.lineWidth = LINE_WIDTH;
    this.context.beginPath();
    this.context.moveTo(starA.x, starA.y);
    this.context.lineTo(starB.x, starB.y);
    this.context.stroke();
    this.context.closePath();
  }

  drawConnections() {
    const starCount = this.stars.length;
    const lineDistSq = MAX_LINE_LENGTH ** 2;
    const fadeDistance = (MAX_LINE_LENGTH - LINE_FADE_LENGTH) ** 2;

    for (let i = 0; i < starCount; i++) {
      for (let j = i + 1; j < starCount; j++) {
        const starA = this.stars[i];
        const starB = this.stars[j];
        const dist = (starA.x - starB.x) ** 2 + (starA.y - starB.y) ** 2;
        if (dist <= lineDistSq) {
          let opacityMult = 1;
          if (dist >= fadeDistance)
            opacityMult =
              1 - (dist - fadeDistance) / (lineDistSq - fadeDistance);
          this.drawLine(starA, starB, opacityMult);
        }
      }
    }
  }

  start() {
    this.run = true;
    this.frameReq = requestAnimationFrame(this.tick);
  }

  stop() {
    this.run = false;
    cancelAnimationFrame(this.frameReq);
    this.frameReq = null;
  }
}

class ShootingStars {
  constructor(el) {
    this.el = el;
    this.running = false;
    this.handle = null;
  }

  spawnShootingStar() {
    const div = document.createElement("div");
    div.className = "shooting-star";
    div.style.top = `${rand(-75, 75)}vw`;
    const that = this.el.appendChild(div);
    let cleaned = false;
    div.onanimationend = () => {
      if (!cleaned) {
        cleaned = true;
        this.el.removeChild(that);
      }
    };

    if (this.running)
      this.handle = setTimeout(
        () => this.spawnShootingStar(),
        rand(MIN_FALLING_STAR_TIMER, MAX_FALLING_STAR_TIMER)
      );
  }

  start() {
    this.running = true;
    this.handle = setTimeout(
      () => this.spawnShootingStar(),
      rand(MIN_FALLING_STAR_TIMER, MAX_FALLING_STAR_TIMER)
    );
  }

  stop() {
    this.running = false;
    clearTimeout(this.handle);
    this.handle = null;
  }
}

export default {
  name: "nightsky-background",
  mounted() {
    this.constellation = new ConstellationSim(this.$refs.canvas);
    this.constellation.start();
    this.shooting = new ShootingStars(this.$refs.starContainer);
    this.shooting.start();
  },
  beforeUnmount() {
    this.constellation.stop();
    this.constellation = null;
    this.shooting.stop();
    this.shooting = null;
  },
};
</script>

<style lang="stylus" scoped>
$shooting-time = 5000ms

.nightsky-background
  position absolute
  left 0
  right 0
  width 100vw
  height 100vh

  .stars-canvas
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  .shooting-wrapper
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    overflow hidden

  .shooting-container
    position absolute
    left 0
    top 0
    width 1rem
    height 1rem
    transform rotateZ(45deg)

    >>> .shooting-star
      position absolute
      left 0vw
      top 75vh
      height 2px
      background linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0))
      border-radius 999px
      filter drop-shadow(0 0 6px rgba(105, 155, 255, 1))
      animation tail $shooting-time ease-in-out forwards, shooting $shooting-time ease-in-out forwards

      &::before
        content ''
        position absolute
        top calc(50% - 1px)
        right 0
        height 2px
        background linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0))
        transform translateX(50%) rotateZ(45deg)
        border-radius 100%
        animation shining $shooting-time ease-in-out forwards

      &::after
        content ''
        position absolute
        top calc(50% - 1px)
        right 0
        height 2px
        background linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0))
        transform translateX(50%) rotateZ(-45deg)
        border-radius 100%
        animation shining $shooting-time ease-in-out forwards

@keyframes tail
  0%
    width 0

  30%
    width 20vw

  100%
    width 0

@keyframes shining
  0%
    width 0

  50%
    width 30px

  100%
    width 0

@keyframes shooting
  0%
    transform translateX(0)

  100%
    transform translateX(90vw)
</style>